<template >
  <div id="EditPurchase">
    <div class="">
      <div id="header" class="col-md-12">
        <Header :name="user.fullname" />
      </div>
      <div class="mxwd">
        <div id="content" class="dflx">
          <div id="" class="">
            <SideMenu :indexsec="4" />
          </div>
          <div id="stn-editpedido">
            <div id="purchaseDetail">
              <div class="contentPD">
                <div class="sec1_cpd">
                  <div class="sec1_cpd_titles">
                    <p class="cpdt1">NÚMERO DEL PEDIDO</p>
                    <p class="cpdt2">FECHA</p>
                    <p class="cpdt3">TOTAL</p>
                    <p class="cpdt4">MÉTODO DE PAGO</p>
                  </div>
                  <div class="sec1_cpd_data">
                    <p class="cpdd1">{{ data.purchase_num }}</p>
                    <!--  pendiente agregar numero pedido -->
                    <p class="cpdd2">{{ data.purchase_date }}</p>
                    <!--  pendiente agregar fecha -->
                    <p class="cpdd3">${{ data.total }}</p>
                    <p v-if="data.method_payment == 'OpenPay'" class="cpdd4">
                      Openpay
                    </p>
                    <p v-else class="cpdd4">{{ data.method_payment }}</p>
                  </div>
                </div>
                <div class="sec1_cpd">
                  <div class="sec1_cpd_titles">
                    <p class="cpdd_3rd">DIRECCIÓN</p>
                    <p class="cpdd_3rd">COLONIA</p>
                    <p class="cpdd_3rd">CÓDIGO POSTAL</p>
                  </div>
                  <div class="sec1_cpd_data">
                    <p class="cpdd_3rd">{{ data.address }}</p>
                    <p class="cpdd_3rd">{{ data.address2 }}</p>
                    <p class="cpdd_3rd">{{ data.postal_code }}</p>
                  </div>
                </div>
                <div class="sec1_cpd">
                  <div class="sec1_cpd_titles">
                    <p class="cpdd_2nd">CIUDAD/ESTADO</p>
                    <p class="cpdd_2nd">TELEFONO</p>
                  </div>
                  <div class="sec1_cpd_data">
                    <p class="cpdd_2nd">{{ data.city }}, {{ data.state }}</p>
                    <p class="cpdd_2nd">{{ data.phone }}</p>
                  </div>
                </div>
                <div v-if="data.method_payment == 'OpenPay'" class="sec1_cpd">
                  <p class="subtitleID">Openpay ID's</p>
                  <div class="sec1_cpd_title2">
                    <p class="cpdt1">Order ID</p>
                    <p class="cpdt4">CUSTOMER ID</p>
                  </div>
                  <div class="sec1_cpd_data">
                    <p class="cpdd1">{{ data.order_id }}</p>
                    <!--  pendiente agregar numero pedido -->
                    <p class="cpdd4">{{ data.payer_id }}</p>
                    <!--  pendiente agregar fecha -->
                  </div>
                </div>
                <div class="tablaDP">
                  <div class="headerTPD">
                    <p>DETALLES DEL <span clr>PEDIDO</span></p>
                  </div>
                  <div class="contentTPD">
                    <div class="sec1_cnt">
                      <div class="sec1_cnt_prd">
                        <div class="dflx">
                          <p class="sctitle_r cntt1">PRODUCTO</p>
                          <p class="sctitle_r">PRECIO</p>
                        </div>
                        <div class="items_cnt">
                          <!-- productos comprados -->
                          <div
                            v-for="(item, key) in data.detail"
                            :key="key"
                            class="item_cnt dflx"
                          >
                            <div class="item_cntinf">
                              <p class="pbl">{{ item.name }}</p>
                              <p>{{ item.size }} | {{ item.color }}</p>
                              <p>{{item.data.category_name}}</p>
                              <p>código:{{item.data.code}}</p>
                              <p class="pbl">x{{ item.quantity }}</p>
                            </div>
                            <div class="item_cntprc">
                              <p>{{ item.price }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="border_cnt"></div>
                      <div class="sec2_cnt_sub">
                        <div class="dflx cntsub">
                          <p class="sctitle_r sect2_cntt1">SUBTOTAL</p>
                          <p>${{ data.subtotal }}</p>
                        </div>
                        <div class="dflx">
                          <p class="sctitle_r sect2_cntt1">ENVÍO</p>
                          <p>${{ data.envio }}</p>
                        </div>
                      </div>
                      <div class="border_cnt"></div>
                      <div class="sec3_cnt_mtd">
                        <div class="dflx cntmtd">
                          <p class="sctitle_r sect3_cntt1">
                            MÉTODO <br />
                            DE PAGO
                          </p>
                          <p
                            v-if="data.method_payment == 'OpenPay'"
                            class="sect3_cntt2"
                          >
                            Openpay
                          </p>
                          <p v-else class="sect3_cntt2">
                            {{ data.method_payment }}
                          </p>
                        </div>
                        <div class="dflx">
                          <p class="sctitle_r sect3_cntt1">TOTAL</p>
                          <p class="sect3_cntt2">${{ data.total }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="border_cnt_b"></div>

                    <div
                      class="sec2_cnt_fac"
                      v-if="data.billing != 'No se encontraron coincidencias'"
                    >
                      <!-- si existe facturacion  -->
                      <div class="dflx">
                        <p class="sctitle_r .pbl fact1">RAZÓN SOCIAL</p>
                        <p>{{ data.billing.reason_social }}</p>
                      </div>
                      <div class="dflx">
                        <p class="sctitle_r .pbl fact1">RFC</p>
                        <p>{{ data.billing.rfc }}</p>
                      </div>
                      <div class="dflx">
                        <p class="sctitle_r .pbl fact1">DIRECCIÓN FISCAL</p>
                        <p>{{ data.billing.fiscal_address }}</p>
                      </div>
                      <div class="dflx">
                        <p class="sctitle_r .pbl fact1">CÓDIGO POSTAL</p>
                        <p>{{ data.billing.postal_code }}</p>
                      </div>
                      <div class="dflx">
                        <p class="sctitle_r .pbl fact2">CORREO ELECTRÓNICO</p>
                        <p>{{ data.billing.email }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           

            <div v-if="user.role == 'Legrafica' || user.role == 'legrafica'">
              <button class="btnEliminar" v-b-modal="'modal-delete' + data.id">
                ELIMINAR
              </button>

              <b-modal
                :id="'modal-delete' + data.id"
                ref="modal-eliminar-charge"
                no-close-on-backdrop
                centered
                hide-footer
                hide-header
              >
                <div class="d-block text-center">
                  <img :src="closeIcon" class="icon3 pt-5" /><br />
                  <p class="modalHeader pt-3 color-four">Eliminar pago</p>
                  <p class="modalBody color-one">
                    ¿Estás seguro que deseas eliminar este pago?
                  </p>
                </div>
                <div class="wrapper">
                  <b-button class="one py-3 btnleft btns" @click="hideModal()"
                    >Cancelar</b-button
                  >
                  <b-button class="two btnright btns" @click="deleteCharge()"
                    >Eliminar</b-button
                  >
                </div>
              </b-modal>
            </div>

            <div class="form_editpedido box_content" id="stn-edituser">
              <form @submit.prevent="submit(data.status, num_seg)">
                <div class="header_edit">
                  <p>EDITAR ESTATUS</p>
                </div>

                <div class="dflx row-one mb-5">
                  <div class="mlft">
                    <label class="labelR" for="inputR">Estatus</label>
                    <p
                      v-if="data.status == 'CARGO PENDIENTE'"
                      class="securemsg"
                    >
                      El cliente esta en proceso de validar el pago mediante 3d
                      Secure
                    </p>
                    <p
                      v-if="data.status == '3D SECURE EXPIRADO'"
                      class="securemsg"
                    >
                      El cliente no completo la validación del pago mediante 3d
                      Secure
                    </p>

                    <b-dropdown
                      v-if="
                        data.status != 'CARGO PENDIENTE' &&
                        data.status != '3D SECURE EXPIRADO'
                      "
                      class="drp"
                    >
                      <template #button-content>
                        <div class="row">
                          <span class="drop-text">{{ data.status }}</span>
                        </div>
                      </template>

                      <div v-for="(name, key) in status" :key="key">
                        <b-dropdown-item @click="changeStatus(name)">
                          {{ name }}
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>

                  <div v-if="data.status == 'ENVIADO'" class="CEF3_B2">
                    <label class="labelTel color-1" for="inputTel"
                      >Numero de seguimiento</label
                    >

                    <b-form-input
                      class="Input_Estadio"
                      v-model="num_seg"
                      required
                      id="inputTel"
                      type="text"
                      pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    />
                    <p class="num_seg">Actual : {{ data.num_seg }}</p>
                  </div>
                </div>

                <div class="row mx-0 row-one">
                  <div class="div-error" v-if="this.status == 'error'">
                    <p class="msg-error">{{ this.msg }}</p>
                  </div>
                </div>

                <div class="btn-form">
                  <b-btn
                    v-if="
                      data.status != 'CARGO PENDIENTE' &&
                      data.status != '3D SECURE EXPIRADO'
                    "
                    variant="outline-secondary"
                    class="editar-modal"
                    type="submit"
                    >GUARDAR</b-btn
                  >
                  <b-modal
                    id="modal-editar"
                    ref="modal-editar"
                    no-close-on-backdrop
                    centered
                    hide-footer
                    hide-header
                  >
                    <div class="message-modal">
                      <img :src="IconSuccess" class="success" /><br />
                      <p class="text-modal">
                        El estatus se edito correctamente
                      </p>
                    </div>
                    <div class="">
                      <b-button class="btn-modal" @click="editarOtro()"
                        >CONFIRMAR</b-button
                      >
                      <b-button
                        type="button"
                        aria-label="Close"
                        class="close-modal"
                        @click="salir()"
                        >×</b-button
                      >
                    </div>
                  </b-modal>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from "../../components/SideMenu";
import Header from "../../components/Header";

import IconSuccess from "../../assets/Vector.png";
import { mapActions } from "vuex";

import eliminar from "../../assets/eliminar.png";
import i_logout from "../../assets/iconocerrar.png";
let urlpath = require("../../global/url");
export default {
  name: "EditPurchase",
  components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      status: "",
      msg: "",
      IconSuccess: IconSuccess,
      status_form: "",
      num_seg: "",
      eliminar: eliminar,
      closeIcon: i_logout,
      status: [
        "PREPARANDO",
        "ENVIADO",
        "COMPLETADO",
        "CANCELADO",
        "FALLIDO",
        "REEMBOLSO",
      ],
    };
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  async created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "pedido",
    };
    await this.getInfoById(payload);

    const response = await this.$store.dispatch("main/editItem", {
      option: "pedidos/charge_status",
      item: { order_id: this.data.order_id },
    });

    await this.getInfoById(payload);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    data() {
      return this.$store.getters["main/data"];
    },
  },
  methods: {
    ...mapActions("main", ["getInfoById"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (name, num_seg) {
      let id = this.$route.params.id;

      this.status = "";
      this.msg = "";

      if (name == "ENVIADO" && num_seg == "SIN ASIGNAR") {
        this.status = "error";
        this.msg = "Inserte un numero de seguimiento valido.";
      } else {
        let data = {
          id: id,
          estatus: name,
          num_seg: num_seg,
        };

        let result = await this.$store.dispatch("main/editItem", {
          option: "pedidos",
          item: data,
        });

        if (result.status == "error") {
          this.status = "error";
          this.msg = result.message;
        } else {
          // success
          this.showModal();
        }
      }
    },

    editarOtro: function () {
      this.wait();
    },
    salir: function () {
      this.$router.push("/administrador").catch((err) => {});
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
    changeStatus: function (name) {
      this.status_form = name;
      this.data.status = name;
    },
    hideModal() {
      this.$refs["modal-eliminar-charge"].hide();
    },
    deleteCharge: async function () {
      const payload = {
        option: "pedido",
        id: this.data.id,
        purchase_num: this.data.purchase_num,
      };

      let result = await this.$store.dispatch("main/deleteItem", payload);

      if (result.status == "error") {
        //this.wait();
      } else {
        this.$router.push("/administrador").catch((err) => {});
      }
    },
  },
};
</script>
<style scoped>
button.btnEliminar {
  margin-top: 2vw;

  margin-left: 80%;
  width: 10vw;
  height: 4vw;
  background-color: var(--color-1);
  color: var(--color-4);
  border: 0px;
  border-color: 0vw !important;
  border-radius: 1vw !important;
  font-size: 0.9027777777777777vw;
  font-weight: 800;
  letter-spacing: 0.1388888888888889vw;
  transition: all 300ms;
}
button.btnEliminar:hover {
  background-color: var(--color-2);
}
p.securemsg {
  color: #b70d1d;
  font-size: 1vw;
  font-weight: 600;
}
p.num_seg {
  margin-top: 0.5vw;
  margin-left: 0.2vw;
  color: #726d6d;
}
.dflx {
  display: flex;
}
.mxwd {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.box_content {
  width: 74vw;
  border: 0.06944444444444445vw solid var(--color-8);
  border-radius: 2.8vw;
  margin-bottom: 5vw;
  margin-left: 3vw;
}

.header_edit {
  width: 100%;
  height: 4.791666666666667vw;
  background-color: var(--color-8);
  margin-top: 2.65625vw;
  margin-bottom: 3.6458333333333335vw;
  color: #e5e5e5;
}

.header_edit p {
  color: var(--color-4);
  font-size: 1.25vw;
  font-weight: 800;
  padding-top: 1.7361111111111112vw;
  text-align: center;
}

#stn-editpedido .btn-form button {
  margin-bottom: 1.84375vw;
}

.mlft {
  margin-left: 15vw;
}

p.subtitleID {
  margin-left: 10vw;
  margin-top: 2vw;
  font-size: 1.25vw;
  font-weight: 700;
  text-decoration: underline;
  color: var(--color-2);
}
.sec1_cpd_title2 {
  margin-top: 1.4895833333333335vw;
  display: flex;
  margin-left: 9.614583vw;
  color: var(--color-7);
}

#purchaseDetail {
  font-family: "Rajdhani";
}
.headerPD {
  height: 10.260416666666666vw;
  margin-top: 4.583333333333333vw;
  background: linear-gradient(90deg, #b70d1d 0%, #032a4b 100%);
}
.headerPD h1 {
  text-align: center;
  color: var(--color-7);
  font-weight: 700;
  font-size: 3.75vw;
  padding-top: 0.78125vw;
}
.headerPD p {
  text-align: center;
  color: var(--color-4);
  font-size: 1.875vw;
}
.sec1_cpd_titles {
  margin-top: 3.4895833333333335vw;
  display: flex;
  margin-left: 9.614583vw;
  color: var(--color-7);
}
.sec1_cpd_titles p,
.sec1_cpd_title2 p {
  font-size: 1.25vw;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
}
.cpdt1 {
  width: 14.531250000000002vw;
  margin-right: 7.291666666666667vw;
}
.cpdt2 {
  width: 2.96875vw;
  margin-right: 11.041666666666666vw;
}
.cpdt3 {
  width: 2.8125vw;
  margin-right: 11.458333333333332vw;
}
.cpdt4 {
  width: 12.239583333333332vw;
}
.sec1_cpd_data {
  display: flex;
  margin-left: 9.614583vw;
  color: var(--color-1);
}
.sec1_cpd_data p {
  font-size: 1.25vw;
  text-align: center;
  font-family: "Rajdhani";
  font-weight: 600;
}
.cpdd1 {
  width: 12.416667vw;
  margin-right: 5.791667vw;
}
.cpdd2 {
  width: 10.416666666666668vw;
  margin-right: 3.641667vw;
}
.cpdd3 {
  width: 10.416666666666668vw;
  margin-right: 3.458333vw;
}
.cpdd4 {
  width: 20.46875vw;
}
.cpdd_3rd {
  width: 33%;
}
.cpdd_2nd {
  width: 50%;
}

.tablaDP {
  margin-top: 3.4375000000000004vw;
  width: 67.5vw;
  margin-left: 7.25vw;
}
.headerTPD {
  height: 5.677083333333333vw;
  background-color: #b70d1d;
  border-radius: 2.604166666666667vw 2.604166666666667vw 0vw 0vw;
}
.headerTPD p {
  width: 19.88645833333333vw;
  font-size: 1.875vw;
  font-family: "Rajdhani";
  color: var(--color-4);
  margin-left: 23.802083333333332vw;
  padding-top: 1.7708333333333333vw;
}
.headerTPD p span {
  color: var(--color-7);
  font-family: "Rajdhani";
}
.contentTPD {
  min-height: 35.208333333333336vw;
  background-color: #e5e5e5;
  margin-bottom: 5.052083333333333vw;
  padding-top: 2.9166666666666665vw;
  font-weight: 600;
  border-radius: 0vw 0vw 2.604166666666667vw 2.604166666666667vw;
}

.sec1_cnt {
  margin-bottom: 2.2916666666666665vw;
  font-size: 1.2583333333333333vw;
  font-family: "Rajdhani";
}
.hr_h {
  border: 1px solid #b70d1d;
}
.sec1_cnt {
  display: flex;
}

.sctitle_r {
  font-family: "Rajdhani";
  font-weight: 700;
  color: #b70d1d;
}

.dflx {
  display: flex;
}

.border_cnt {
  border-right: 0.11vw solid #b70d1d;
  margin-top: 1.2vw;
}

.border_cnt_b {
  border-bottom: 0.11vw solid #b70d1d;
  width: 55.51666666666667vw;
  margin-left: 5.989583333333334vw;
  margin-bottom: 2.8125vw;
}

.sec1_cnt_prd {
  width: 21.1453125vw;
  margin-left: 5.3125vw;
}
.cntt1 {
  margin-right: 6.95vw;
}

.sec2_cnt_sub {
  width: 15.854167vw;
}
.item_cnt {
  padding-top: 0.8854166666666666vw;
}
.items_cnt p {
  width: 10.208333333333334vw;
  color: var(--color-1);
  margin-bottom: 0vw;
}
.pbl {
  font-family: "Rajdhani";
}
.item_cntprc {
  text-align: center;
}

.cntsub {
  margin-bottom: 4.53125vw;
}
.sec2_cnt_sub {
  margin-left: 2.604166666666667vw;
}

.sect2_cntt1 {
  width: 7vw;
}

.cntmtd {
  margin-bottom: 2.96875vw;
}

.sec3_cnt_mtd {
  margin-left: 2.2916666666666665vw;
}
.sect3_cntt1 {
  width: 6vw;
}
.sect3_cntt2 {
  width: 8.708333vw;
}

.sec2_cnt_fac {
  margin-left: 5.3125vw;
  font-family: "Rajdhani";
  font-family: 1.25vw;
}

.fact1,
.fact2 {
  width: 11.393229166666668vw;
  margin-right: 3.4505208333333335vw;
}
.fact1 {
  margin-bottom: 1.3541666666666667vw;
}
.fact2 {
  margin-bottom: 3.229166666666667vw;
}
</style>